.App {
	text-align: center;
	height: 100%;
}

.App-logo {
	height: 20vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		/* animation: App-logo-spin infinite 20s linear; */
	}
}

.App-header {
	background-color: #f7f7f7;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.button {
	background-color: #4caf50; /* Green */
	border: none;
	color: white;
	padding: 5px 12px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 13px;
	margin: 4px 2px;
	cursor: pointer;
}

.button1 {
	background-color: #045624;
	color: white;
}
.button1::after {
	background-color: #045624;
	color: white;
	opacity: 0;
	transition: all 0.8s;
}
.button1:active::after {
	padding: 0;
	margin: 0;
	opacity: 1;
	transition: 0s;
}

.button2 {
	background-color: white;
	color: #045624;
	border: 1px solid green;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.link {
	text-decoration: none;
}
.goback {
	margin-top: 20px;
	color: #045624;
	background-color: #f7f7f7;
	font-size: 20px;
	font-weight: bold;
	border: 1px solid #045624;
}
